import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { serialize } from "object-to-formdata";
import axios from "../utilities/axios";
import { assignOperator } from "./conversation";
import { fetchUser } from "./user";
const initialState = {
  operators: [],
  meta: [],
  operator: {},
  pendingOperators: [],
  filteredOperators: [],
  departments: [],
  statusGetDepartments: "idle",
  statusUpdateDepartment: "idle",
  statusCreateDepartment: "idle",
  statusDeleteDepartment: "idle",
  departmentsMeta: [],
  status: "idle",
  statusInviteOperator: "idle",
  statusUpdateOperator: "idle",
  statusOperatorsList: "idle",
  statusDeleteOperator: "idle",
  statusRevokeOperator: "idle",
  statusUpdatePassword: "idle",
  statusCreateOperator: "idle",
  statusResendInvitation: "idle",
  statusConfirmOperator: "idle",
  error: null,
};
export const fetchOperators = createAsyncThunk(
  "operators/fetchOperators",
  async (query) => {
    let data;
    try {
      const response = await axios.get(
        `/clients/websites/${query.websiteID}/operators${query.query}`
      );
      data = await response.data;
      if (response.status === 200) {
        return { data, query };
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getDepartments = createAsyncThunk(
  "operators/getDepartments",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body?.websiteID}/departments?page=${body?.page}&limit=${body?.limit?.toString() || 15}`
      );
      data = await response.data.data;
      if (response.status === 200) {
        const { docs, ...meta } = data;
        return { docs, meta };
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const updateDepartment = createAsyncThunk(
  "operators/updateDepartment",
  async (body) => {
    let data;
    try {
      const response = await axios.put(
        `/websites/${body?.websiteID}/departments/${body?.id}`,
        body?.data
      );
      data = await response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const deleteDepartment = createAsyncThunk(
  "operators/deleteDepartment",
  async (body) => {
    let data;
    try {
      const response = await axios.delete(
        `/websites/${body?.websiteID}/departments/${body?.id}`
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const createDepartment = createAsyncThunk(
  "operators/createDepartment",
  async (body) => {
    let data;
    try {
      const response = await axios.post(
        `/websites/${body?.websiteID}/departments`,
        body?.data
      );
      data = await response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const createOperator = createAsyncThunk(
  "operators/createOperator",
  async (operator) => {
    let data;
    !operator.data.file && delete operator.data.file;
    try {
      const response = await axios.post(
        `/clients/websites/${operator.websiteID}/operators/create`,
        !operator.data.file
          ? operator.data
          : serialize(operator.data, { indices: true })
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const updateOperator = createAsyncThunk(
  "operators/updateOperator",
  async (operator) => {
    let data;
    !operator.data.file && delete operator.data.file;
    try {
      const response = await axios.put(
        `/clients/websites/${operator.websiteID}/operators/${operator.id}`,
        !operator.data.file
          ? operator.data
          : serialize(operator.data, { indices: true })
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const revokeOperator = createAsyncThunk(
  "operators/revokeOperator",
  async (email) => {
    let data;
    try {
      const response = await axios.post(
        `/clients/websites/${email.websiteID}/operators/revoke`,
        { email: email.email }
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const updatePassword = createAsyncThunk(
  "operators/updateProfile",
  async (passwords, thunkAPI) => {
    let data;
    try {
      const response = await axios.put(`/client/updatePassword`, passwords);
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(fetchUser());
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const InviteOperator = createAsyncThunk(
  "operators/InviteOperator",
  async (operator, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(
        `/clients/websites/${operator.websiteID}/operators`,
        operator.data
      );
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(
          fetchOperators({ websiteID: operator.websiteID, query: "" })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const resendInvitation = createAsyncThunk(
  "operators/ResendInvitation",
  async (body) => {
    let data;
    try {
      const response = await axios.post(
        `/clients/websites/${body.websiteID}/operators/resend`,
        body.data
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const DeleteOperator = createAsyncThunk(
  "operators/DeleteOperator",
  async (id) => {
    let data;
    try {
      const response = await axios.delete(
        `/clients/websites/${id.websiteID}/operators/${id.id}`
      );
      data = await response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const updateOperatorStatus = createAsyncThunk(
  "operators/updateOperatorStatus",
  async (data) => {
    try {
      const response = await axios.put(
        `clients/websites/${data?.websiteId}/user/availability`,
        { availability: data?.availability }
      );
      return response.data;
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getOperatorById = createAsyncThunk(
  "operator/getOperatorById",
  async (body, thunkApi) => {
    let data;
    try {
      const response = await axios.get(
        `/clients/websites/${body.websiteId}/operators/${body.id}`
      );
      data = response.data.data;
      if (response.status === 200) {
        thunkApi.dispatch(
          assignOperator({
            data,
            conversationId: body.conversationId,
            newAssignment: body?.newAssignment,
            changeOperator: body.changeOperator,
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const confirmOperator = createAsyncThunk(
  "operator/confirmOperator",
  async (body) => {
    let data;
    !body.values.file && delete body.values.file;
    try {
      const response = await axios.post(
        `/client/websites/${body.websiteId}/operators/${body.token}`,
        !body.values.file
          ? body.values
          : serialize(body.values, { indices: true })
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setOperatorSuccess: (state, action) => {
      state.operator = action.payload;
    },
  },
  extraReducers: {
    [InviteOperator.pending]: (state) => {
      state.statusInviteOperator = "loading";
    },
    [InviteOperator.fulfilled]: (state, action) => {
      state.statusInviteOperator = "succeeded";
    },
    [InviteOperator.rejected]: (state, action) => {
      state.statusInviteOperator = "failed";
      state.error = action.payload;
    },
    [DeleteOperator.pending]: (state) => {
      state.statusDeleteOperator = "loading";
    },
    [DeleteOperator.fulfilled]: (state, action) => {
      state.statusDeleteOperator = "succeeded";
      state.operators = state.operators.filter(
        (operator) => operator._id !== action.payload._id
      );
    },
    [DeleteOperator.rejected]: (state, action) => {
      state.statusDeleteOperator = "failed";
      state.error = action.payload;
    },
    [revokeOperator.pending]: (state) => {
      state.statusRevokeOperator = "loading";
    },
    [revokeOperator.fulfilled]: (state, action) => {
      state.statusRevokeOperator = "succeeded";
    },
    [revokeOperator.rejected]: (state, action) => {
      state.statusRevokeOperator = "failed";
      state.error = action.payload;
    },
    [updatePassword.pending]: (state) => {
      state.statusUpdatePassword = "loading";
    },
    [updatePassword.fulfilled]: (state, action) => {
      state.statusUpdatePassword = "succeeded";
    },
    [updatePassword.rejected]: (state, action) => {
      state.statusUpdatePassword = "failed";
      state.error = action.payload;
    },
    [fetchOperators.pending]: (state) => {
      state.statusOperatorsList = "loading";
    },
    [fetchOperators.fulfilled]: (state, action) => {
      state.statusOperatorsList = "succeeded";
      if (action.payload.query?.query?.includes("isVerified")) {
        state.pendingOperators = action.payload.data.data.docs;
        state.meta = action.payload.data.data.meta;
      } else {
        if (
          action.payload.query?.query?.includes("email") ||
          action.payload.query?.query?.includes("firstName")
        ) {
          state.filteredOperators = action.payload.data.data.docs;
          state.meta = action.payload.data.data.meta;
        } else {
          state.operators = action.payload.data.data.docs;
          state.meta = action.payload.data.data.meta;
        }
      }
    },
    [fetchOperators.rejected]: (state, action) => {
      state.statusOperatorsList = "failed";
      state.error = action.payload;
    },
    [createOperator.pending]: (state) => {
      state.statusCreateOperator = "loading";
    },
    [createOperator.fulfilled]: (state, action) => {
      state.statusCreateOperator = "succeeded";
      state.operators = [action.payload.data, ...state.operators];
    },
    [createOperator.rejected]: (state, action) => {
      state.statusCreateOperator = "failed";
      state.error = action.payload;
    },
    [updateOperator.pending]: (state) => {
      state.statusUpdateOperator = "loading";
    },
    [updateOperator.fulfilled]: (state, action) => {
      state.statusUpdateOperator = "succeeded";
      state.operators = state.operators.map((item) => {
        return item._id === action.payload.data._id
          ? action.payload.data
          : item;
      });
    },
    [updateOperator.rejected]: (state, action) => {
      state.statusUpdateOperator = "failed";
      state.error = action.payload;
    },
    [resendInvitation.pending]: (state) => {
      state.statusResendInvitation = "loading";
    },
    [resendInvitation.fulfilled]: (state, action) => {
      state.statusResendInvitation = "succeeded";
    },
    [resendInvitation.rejected]: (state, action) => {
      state.statusResendInvitation = "failed";
      state.error = action.payload;
    },
    [confirmOperator.pending]: (state) => {
      state.statusConfirmOperator = "loading";
    },
    [confirmOperator.fulfilled]: (state, action) => {
      state.statusConfirmOperator = "succeeded";
    },
    [confirmOperator.rejected]: (state, action) => {
      state.statusConfirmOperator = "failed";
      state.error = action.payload;
    },
    [deleteDepartment.pending]: (state) => {
      state.statusDeleteDepartment = "loading";
    },
    [deleteDepartment.fulfilled]: (state, action) => {
      state.departments = state.departments?.filter(
        (department) => department?._id !== action.payload?.data?._id
      );
      state.statusDeleteDepartment = "success";
    },
    [createDepartment.pending]: (state) => {
      state.statusCreateDepartment = "loading";
    },
    [createDepartment.fulfilled]: (state, action) => {
      state.departments = [action.payload, ...state.departments];
      state.statusCreateDepartment = "success";
    },

    [updateDepartment.pending]: (state) => {
      state.statusUpdateDepartment = "loading";
    },
    [updateDepartment.fulfilled]: (state, action) => {
      state.departments = state.departments?.map((department) =>
        department?._id === action.payload?._id ? action.payload : department
      );
      state.statusUpdateDepartment = "success";
    },
    [getDepartments.pending]: (state) => {
      state.statusGetDepartments = "loading";
    },
    [getDepartments.fulfilled]: (state, action) => {
      state.departments = action.payload.docs;
      state.departmentsMeta = action.payload.meta;
      state.statusGetDepartments = "success";
    },
  },
});
export const reducer = slice.reducer;
const { setOperatorSuccess } = slice.actions;
export default slice;
export const setOperator = (operator) => async (dispatch) => {
  try {
    await dispatch(setOperatorSuccess(operator));
  } catch (error) {
    return console.error(error.message);
  }
};
